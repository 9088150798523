import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react"
import { getTokenFromSessionStorage } from "../common/auth"
import { config } from "../../configuration/app/app-config"

const NOTIFICATION_SETTINGS_API_TAG = "NOTIFICATION_SETTINGS"

const notificationSettingsBaseUrl = config.notificationSettingsApi.baseUrl

export interface NotificationSettingsQueryArgs {
    email: string | undefined
}

export interface NotificationSettingsResultType {
    email: string | undefined
}

export const notificationSettingsApi = createApi({
    reducerPath: "notificationSettingsApi",
    tagTypes: [NOTIFICATION_SETTINGS_API_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: notificationSettingsBaseUrl,
        prepareHeaders: async (headers) => {
            headers.set("authorization", `Bearer ${await getTokenFromSessionStorage()}`)
            headers.set("accept", "application/json")
            return headers
        },
    }),
    endpoints: (builder) => ({
        getNotificationEmail: builder.query<NotificationSettingsResultType, void>({
            query: () => "/email",
            transformResponse: (response: unknown) => {
                if (response === null || response === undefined) {
                    return { email: undefined }
                } else {
                    return response as NotificationSettingsResultType
                }
            },
            providesTags: [{ type: NOTIFICATION_SETTINGS_API_TAG }],
        }),
        addNotificationEmail: builder.mutation<NotificationSettingsResultType, NotificationSettingsQueryArgs>({
            query: (body) => ({
                url: "/email",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: NOTIFICATION_SETTINGS_API_TAG }],
        }),
        deleteNotificationEmail: builder.mutation<NotificationSettingsResultType, void>({
            query: () => ({
                url: "/email",
                method: "DELETE",
            }),
            invalidatesTags: [{ type: NOTIFICATION_SETTINGS_API_TAG }],
        }),
    }),
})

export const {
    useGetNotificationEmailQuery,
    useAddNotificationEmailMutation,
    useDeleteNotificationEmailMutation,
} = notificationSettingsApi
