import { ErrorInfo, ReactNode } from "react"
import * as React from "react"
import * as Sentry from "@sentry/browser"

export class ErrorBoundary extends React.Component<{ children?: ReactNode }, object> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        Sentry.withScope(scope => {
            scope.setExtra("componentStack", errorInfo.componentStack)
            Sentry.captureException(error)
        })
    }

    render(): React.ReactNode {
        return this.props.children
    }
}
