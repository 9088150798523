import * as React from "react"
import { ReactNode } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"
import SimpleButtonDropdown from "@rio-cloud/rio-uikit/SimpleButtonDropdown"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import { getNumDistinctAddresses } from "../../helper/shipmentHelper"
import { formatDateTimeColumn } from "../../helper/formatHelper"
import {
    LoadingAddress,
    LoadingAddressType,
    Shipment,
    ShipmentPriority,
    TransportOrder
} from "../../model/transportorder/transportOrder"
import {
    canTransportOrderBeAccepted,
    isAnyShipmentConfirmed,
    isTransportOrderCancelled,
} from "../../model/transportorder/transportOrderEditing"
import {
    AggregationHint,
    hasContributions,
    MeasurementWithAggregationHint,
    TransportOrderMeasurementSummary
} from "../../model/transportorder/transportOrderMeasurements"
import { hasDangerousGoods } from "../../model/transportorder/transportOrderDangerousGoods"
import ActionButton from "../Application/ActionButton"
import { getTimezoneAtLoadingAddress } from "../../timezones/timezones"

const TEST_ID_DETAILS_DROPDOWN = "DETAILS_DROPDOWN"

export const TimeWindowComponent = (props: { firstLoadingAddress?: LoadingAddress, lastUnloadingAddress?: LoadingAddress }): React.ReactElement => {
    return (
        <React.Fragment>
            <div className="display-flex flex-row no-wrap">
                <div className="display-flex flex-column width-20pct">
                    <FormattedMessage id={"transportOrder.start"}/>
                </div>
                &nbsp;
                <div className="display-flex text-bold flex-row no-wrap">
                    {formatDateTimeColumn(
                        props.firstLoadingAddress?.time_window?.time_from ?? props.firstLoadingAddress?.time_window?.time_to,
                        getTimezoneAtLoadingAddress(props.firstLoadingAddress)
                    )}
                </div>
            </div>
            <div className="display-flex flex-row no-wrap">
                <div className="display-flex flex-column width-20pct">
                    <FormattedMessage id={"transportOrder.end"}/>
                </div>
                &nbsp;
                <div className="display-flex text-bold flex-row no-wrap">
                    {formatDateTimeColumn(
                        props.lastUnloadingAddress?.time_window?.time_to,
                        getTimezoneAtLoadingAddress(props.lastUnloadingAddress)
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export const AddressComponent = (props: { loadingAddress?: LoadingAddress }): React.ReactElement => {
    if (!props.loadingAddress) {
        return <React.Fragment/>
    }

    const address = props.loadingAddress.address
    return (
        <div className="display-flex flex-column ellipsis-1">
            <span className="text-bold ellipsis-1">{props.loadingAddress.name}</span>
            <span className="ellipsis-1">{address?.street} {address?.house_number}</span>
            <span className="ellipsis-1">{address?.postal_code} {address?.city}</span>
        </div>
    )
}

export const MoreAddressesLabel = (props: { shipments: Shipment[], loadingAddressType: LoadingAddressType }): React.ReactElement | null => {
    const intl = useIntl()

    const numDistinctAddresses = getNumDistinctAddresses(props.shipments, props.loadingAddressType)
    if (numDistinctAddresses <= 1) {
        return null
    }

    const tooltip = (
        <Tooltip className="width-auto">
            {intl.formatMessage({ id: "transportOrder.overview.moreThanOneLoadingAddresses" },
                { numFurtherAddresses: numDistinctAddresses - 1 })}
        </Tooltip>)

    return <div className="padding-left-5">
        <OverlayTrigger placement="right" overlay={tooltip}>
            <span className="badge bg-info">+{numDistinctAddresses - 1}</span>
        </OverlayTrigger>
    </div>
}

export const TransportTypes = (props: { shipments: Shipment[] }): React.ReactElement => {

    const hasUrgentShipment = props.shipments.some(it => it.priority === ShipmentPriority.URGENT)
    const notUrgentShipments = props.shipments.filter(it => it.priority !== ShipmentPriority.URGENT)

    const transportTypes: Array<ReactNode> = []

    if (notUrgentShipments.length > 0) {
        transportTypes.push(
            notUrgentShipments.map(it => it.processIndicator)
                .filter(onlyUniqueFilter)
                .map(it => (<span key={it}>{it}</span>))
        )
    }
    if (hasUrgentShipment) {
        transportTypes.push(<FormattedMessage key={"urgent"} id={"transportOrder.transportType.urgent"} tagName={"span"}/>)
    }

    return <React.Fragment>
        <span>{joinWithComma(transportTypes)}</span>
    </React.Fragment>
}

const joinWithComma = (nodes: ReactNode[]): ReactNode => {
    if (nodes.length === 0) {
        return <></>
    }
    return nodes.reduce((prev: ReactNode, cur: ReactNode,) => [prev, ", ", cur])
}

const onlyUniqueFilter = (value: string, index: number, self: Array<string>): boolean => self.indexOf(value) === index

export const WeightsByFreightType = (props: { summary: TransportOrderMeasurementSummary }): React.ReactElement => {
    const summary = props.summary
    return <div className="display-flex flex-wrap flex-column align-items-end align-content-center">
        <Measurement display={hasContributions(summary.CARGO)} measurement={summary.CARGO.weight}
            unitTranslationKey={"transportOrder.shipment.cargo.weight.unit.kgm"}/>
        <Measurement display={hasContributions(summary.BULK_DRY)} measurement={summary.BULK_DRY.weight}
            unitTranslationKey={"transportOrder.shipment.cargo.weight.unit.kgm"}/>
        <Measurement display={hasContributions(summary.BULK_LIQUID)} measurement={summary.BULK_LIQUID.weight}
            unitTranslationKey={"transportOrder.shipment.cargo.weight.unit.kgm"}/>
    </div>
}

const MissingDataHint = (): React.ReactElement | null => {

    const intl = useIntl()

    const tooltip = <Tooltip>
        {intl.formatMessage({ id: "transportOrder.overview.freight.aggregate.warning.incompleteData" })}
    </Tooltip>

    return <OverlayTrigger overlay={tooltip} placement={"right"}>
        <span className={"rioglyph rioglyph-warning-sign"} data-testid={"missing-data-hint"}/>
    </OverlayTrigger>
}

function Measurement<T>(props: { display: boolean, measurement: MeasurementWithAggregationHint<T>, unitTranslationKey: string | undefined }): React.ReactElement | null {
    if (!props.display) {
        return null
    }
    if (props.measurement.value === undefined) {
        return <span>-</span>
    }
    let unitDisplay: React.ReactElement | null = null
    if (props.unitTranslationKey) {
        unitDisplay = <React.Fragment>
            &nbsp;
            <FormattedMessage id={props.unitTranslationKey}/>
        </React.Fragment>
    }
    return <span>
        <FormattedNumber value={props.measurement.value} maximumFractionDigits={3}/>
        {unitDisplay}
        {props.measurement.aggregationHint === AggregationHint.MISSING_DATA ? <span>&nbsp;<MissingDataHint/></span> : null}
    </span>
}

export const VolumesByFreightType = (props: { summary: TransportOrderMeasurementSummary }): React.ReactElement => {
    const summary = props.summary
    return <div className="display-flex flex-wrap flex-column align-items-end align-content-center">
        <Measurement display={hasContributions(summary.CARGO)} measurement={summary.CARGO.volume}
            unitTranslationKey={"transportOrder.shipment.cargo.volume.unit.mtq"}/>
        <Measurement display={hasContributions(summary.BULK_DRY)} measurement={summary.BULK_DRY.volume}
            unitTranslationKey={"transportOrder.shipment.cargo.volume.unit.mtq"}/>
        <Measurement display={hasContributions(summary.BULK_LIQUID)} measurement={summary.BULK_LIQUID.volume}
            unitTranslationKey={"transportOrder.shipment.cargo.volume.unit.mtq"}/>
    </div>
}

export const HandlingUnitCountByFreightType = (props: { summary: TransportOrderMeasurementSummary }): React.ReactElement => {
    const summary = props.summary
    return <div className="display-flex flex-wrap flex-column align-items-end align-content-center">
        <Measurement display={hasContributions(summary.CARGO)} measurement={summary.CARGO.count}
            unitTranslationKey={undefined}/>
        <Measurement display={hasContributions(summary.BULK_DRY)} measurement={summary.BULK_DRY.count}
            unitTranslationKey={undefined}/>
        <Measurement display={hasContributions(summary.BULK_LIQUID)} measurement={summary.BULK_LIQUID.count}
            unitTranslationKey={undefined}/>
    </div>
}

interface TransportOrderActionsDropdownProps {
    transportOrder: TransportOrder
    onDetailsClicked: (transportOrder: TransportOrder) => void
    onAcceptClicked: (transportOrder: TransportOrder) => void
}

const canTransportOrderBeAcceptedFromOverview = (transportOrder: TransportOrder): boolean => {
    return canTransportOrderBeAccepted(transportOrder)
        && !isAnyShipmentConfirmed(transportOrder)
}

export const TransportOrderActionsDropdown = (props: TransportOrderActionsDropdownProps): React.ReactElement => {
    const iconAndText = (cssClass: string, messageId: string, attributes: Record<string, string>): ReactNode =>
        <div {...attributes}>
            <span className={`text-size-20 rioglyph ${cssClass}`}/>&nbsp;
            <FormattedMessage id={messageId}/>
        </div>

    const transportOrder = props.transportOrder

    const items: { value: ReactNode, onSelect?: () => void, disabled?: boolean }[] = [
        {
            value: iconAndText("rioglyph-detail-view-info", "transportOrder.overview.menu.details", {
                "data-testid": "details-menu-entry",
                "data-track-ga-event-trigger": "click",
                "data-track-ga-event-category": "dispatch, transportOrder, transportOrderOverview",
                "data-track-ga-event-action": "detailsButton",
            }),
            onSelect: () => props.onDetailsClicked(transportOrder)
        },
    ]
    if (canTransportOrderBeAcceptedFromOverview(transportOrder)) {
        items.push({
            value: iconAndText("rioglyph-thumbs-up", "transportOrder.accept", {
                "data-track-ga-event-trigger": "click",
                "data-track-ga-event-category": "dispatch, transportOrder, transportOrderOverview",
                "data-track-ga-event-action": "acceptButton",
            }),
            onSelect: () => props.onAcceptClicked(transportOrder),
        })
    }

    return(
        <div data-testid={TEST_ID_DETAILS_DROPDOWN}>
            <SimpleButtonDropdown
                bsSize={"sm"}
                items={items}
                title={<span className="rioglyph rioglyph-option-vertical cursor-pointer text-color-dark hover-text-color-highlight"/>}
                iconOnly={true}
                variant={"link"}
            />
        </div>
    )
}

export const DangerousGoodsIndicator = (props: { transportOrder: TransportOrder }): React.ReactElement => {
    if (hasDangerousGoods(props.transportOrder)) {
        return <span className={"rioglyph rioglyph-ok text-size-18"} data-testid={"dangerous-goods-indicator"}/>
    }
    return <React.Fragment/>
}

export const TransportOrderStatusOrAcceptBadge = (props: { transportOrder: TransportOrder, onAcceptClicked: (transportOrder: TransportOrder) => Promise<void> }): React.ReactElement => {
    const transportOrder = props.transportOrder

    if (isTransportOrderCancelled(transportOrder)) {
        return (<span className="text-color-danger" data-testid={"cancelled-badge"}>
            <FormattedMessage id="transportOrder.cancelled"/>
        </span>)
    }
    if (!canTransportOrderBeAccepted((transportOrder))) {
        return <React.Fragment/>
    }
    if (isAnyShipmentConfirmed(transportOrder)) {
        return <span className="text-color-highlight" data-testid={"accepted-badge"}>
            <FormattedMessage id="transportOrder.accepted"/>
        </span>
    }

    if (canTransportOrderBeAcceptedFromOverview(transportOrder)) {
        return <ActionButton
            data-track-ga-event-trigger="click"
            data-track-ga-event-category="dispatch, transportOrder, transportOrderOverview"
            data-track-ga-event-action="acceptButton"
            btnClass="btn btn-primary"
            onClick={(): Promise<void> => props.onAcceptClicked(transportOrder)}
            disableSuccessDifferentiation={true}
            dataTestId={"accept-button"}>
            <FormattedMessage id="transportOrder.accept"/>
        </ActionButton>
    }

    return <React.Fragment/>
}
